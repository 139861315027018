import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import QRCode from 'react-qr-code';
import moment from 'moment';
import filelist2fill from './images/file-list-2-fill.svg';
import filelist2fillBlack from './images/file-list-2-fill-black.svg';
import logo from './images/logo.png';
import logoBlack from './images/logo-black.png';
import macfill from './images/mac-fill.svg';
import macfillBlack from './images/mac-fill-black.svg';
import './styles.css';
import StatementBarChart from '../../shared/StatementBarChart';
import StatementLineChart from '../../shared/StatementLineChart';
import { getUtility } from '@livable/saas-react-components';

export const TRANSACTION_TYPES = {
  CapCredit: 'Cap Credit',
  StatementCapCredit: 'Statement Cap Credit',
  CustomCharge: 'Custom Charge',
  CustomCredit: 'Custom Credit',
  Credit: 'Credit',
  Fee: 'Fee',
  LateFee: 'Late Fee',
  Payment: 'Payment',
  Refund: 'Refund',
  UtilityCharge: 'Utility Charge',
  Void: 'Void',
  ConvenienceFeeCompensation: 'Convenience Fee Compensation',
  ConvenienceFee: 'Convenience Fee',
  CheckPayment: 'Check Payment',
  StripeFee: 'Stripe Fee',
  AdminFee: 'Admin Fee',
  PaperStatementFee: 'Paper Statement Fee',
  CheckMoneyOrder:'Check Money Order',
  Venmo:'Venmo',
  Zelle:'Zelle',
  Other:'Other',
};

export const TRANSACTION_TYPES_ENUM = {
  CapCredit: 'CapCredit',
  StatementCapCredit: 'StatementCapCredit',
  CustomCharge: 'CustomCharge',
  CustomCredit: 'CustomCredit',
  CheckPayment: 'CheckPayment',
  Credit: 'Credit',
  Fee: 'Fee',
  LateFee: 'LateFee',
  Payment: 'Payment',
  Refund: 'Refund',
  UtilityCharge: 'UtilityCharge',
  Void: 'Void',
  ConvenienceFeeCompensation: 'ConvenienceFeeCompensation',
  ConvenienceFee: 'ConvenienceFee',
  StripeFee: 'StripeFee',
  AdminFee: 'AdminFee',
  PaperStatementFee: 'PaperStatementFee',
  CheckMoneyOrder:'CheckMoneyOrder',
  Venmo:'Venmo',
  Zelle:'Zelle',
  Other:'Other',
};

const StatementV2 = (props) => {
  let [queryParams, setQueryParams] = useSearchParams();
  let [reportData, setReportData] = useState({});
  let [residentData, setResidentData] = useState({});
  let [residentLeaseDetails, setResidentLeaseDetails] = useState({});
  let [residentBillingSettings, setResidentBillingSettings] = useState({});
  let [propertyData, setPropertyData] = useState({});
  let [statementStatisticData, setStatementStatisticData] = useState({});
  let [utilityInfo, setUtilityInfo] = useState([]);
  const [isAutopayEnabled, setIsAutopayEnabled] = useState(false);
  const [tips, setTips] = useState([]);
  const [additionalTransactionsInfo, setAdditionalTransactionsInfo] = useState({});
  let [isSuccess, setIsSuccess] = useState(false);
  let [isError, setIsError] = useState(false);

  const getIsPaperLetter = () => {
    return queryParams.get('isPaperLetter').toLowerCase() === 'true';
  };
  const getMaxChargesCreditsNumberReached = () => {
    const maxItemsNumber = 26;
    return reportData?.statementCharges?.items?.length >= maxItemsNumber || reportData?.statementCredits?.items?.length >= maxItemsNumber
  }

  const maxChargeItemsInListPDF = 39;
  const maxChargeItemsInListLetter = 34;

  const PageBreakComponent = () => {
    return (
      <>
        <div
          className="row-with-line"
          key={'page_divider_placeholder_1'}
        >
          <div className="blank-placeholder-for-page-separartion" style={{color: '#FFFFFF'}}>{' - '}</div>
        </div>
        <div
          className="row-with-line"
          key={'page_divider_placeholder_2'}
        >
          <div className="blank-placeholder-for-page-separartion" style={{color: '#FFFFFF'}}>{' - '}</div>
        </div>
      </>
    )
  }

  const login = async (email, password, companyId) => {
    const loginResponse = await fetch(
      `${process.env.REACT_APP_API_GATEWAY_BASE_URL}/account/api/admin/login`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          email: email,
          password: password,
          companyId: companyId,
        }),
      }
    );
    const loginData = await loginResponse.json();
    return loginData;
  };

  const getUtilityInfos = async ({accessToken, utilityIds, propertyId}) => {
    if (props.getFlagIsEnabled('isBillAutomationEnabled')) {
      const utilityInfosResponse = await fetch(
          `${process.env.REACT_APP_API_GATEWAY_BASE_URL}/property/api/propertyutility/info`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            method: 'POST',
            body: JSON.stringify({propertyId, utilityIds}),
          }
      );
      const utilityInfosData = await utilityInfosResponse.json();
      return utilityInfosData;

    } else {
      const utilityInfosResponse = await fetch(
          `${process.env.REACT_APP_API_GATEWAY_BASE_URL}/property/api/utility/getUtilityInfo`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            method: 'POST',
            body: JSON.stringify({utilityIds}),
          }
      );
      const utilityInfosData = await utilityInfosResponse.json();
      return utilityInfosData;
    }
  };

  // // Note: deprecated
  // const getStatement = async ({accessToken, accountId, year, month}) => {
  //   const loginResponse = await fetch(
  //     `${process.env.REACT_APP_API_GATEWAY_BASE_URL}/payment/api/statements/accountStatement?accountId=${accountId}&year=${year}&month=${month}`,
  //     {
  //       headers: {
  //         Authorization: `Bearer ${accessToken}`,
  //         Accept: 'application/json',
  //         'Content-Type': 'application/json',
  //       },
  //       method: 'GET',
  //     }
  //   );
  //   const statementData = await loginResponse.json();
  //   return statementData;
  // };

  const getStatementById = async ({accessToken, statementId}) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_GATEWAY_BASE_URL}/payment/api/statements/statement/${statementId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'GET',
      }
    );
    const statementData = await response.json();
    return statementData;
  };

  const getAccountAutopay = async ({accessToken, accountId}) => {
    const loginResponse = await fetch(
      `${process.env.REACT_APP_API_GATEWAY_BASE_URL}/payment/api/stripe/autopay-method/${accountId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'GET',
      }
    );
    const response = await loginResponse.json();
    return response;
  };

  const getResidentBillingSettings = async ({accessToken, residencyId}) => {
    const apiResp = await fetch(
      `${process.env.REACT_APP_API_GATEWAY_BASE_URL}/property/api/residencies/${residencyId}/billingSettings`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'GET',
      }
    );
    const response = await apiResp.json();
    return response;
  };

  const getResidentUnitOptOut = async ({accessToken, residencyId}) => {
    const apiResp = await fetch(
      `${process.env.REACT_APP_API_GATEWAY_BASE_URL}/property/api/residencies/${residencyId}/unit/utilityoptouts`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'GET',
      }
    );
    const response = await apiResp.json();
    return response;
  };

  const getResidentInfo = async ({accessToken, accountIds}) => {
    const residentInfosResponse = await fetch(
      `${process.env.REACT_APP_API_GATEWAY_BASE_URL}/account/api/account/profiles`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({accountIds}),
      }
    );
    const residentInfosData = await residentInfosResponse.json();
    return residentInfosData;
  };

  const getResidentLeaseDetails = async ({accessToken, residencyId}) => {
    const getResidentLeaseDetailsResponse = await fetch(
      `${process.env.REACT_APP_API_GATEWAY_BASE_URL}/property/api/residencies/${residencyId}/lease-details`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'GET',
      }
    );
    const getResidentLeaseDetailsData =
      await getResidentLeaseDetailsResponse.json();
    return getResidentLeaseDetailsData;
  };

  const getPropertyInfo = async ({accessToken, propertyId}) => {
    const propertyInfoResponse = await fetch(
      `${process.env.REACT_APP_API_GATEWAY_BASE_URL}/property/api/properties/${propertyId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'GET',
      }
    );
    const propertyInfoData = await propertyInfoResponse.json();
    return propertyInfoData;
  };

  const getAllocationTablesInfo = async ({accessToken, billingCycleIds}) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_GATEWAY_BASE_URL}/allocation/api/allocations/allocationTablesInfo`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({billingCycleIds}),
      }
    );
    const responseData = await response.json();
    return responseData;
  };

  const getTransactionsDetails = async ({accessToken, transactionIds}) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_GATEWAY_BASE_URL}/payment/api/transactionhistoryv2/list`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(transactionIds),
      }
    );
    const responseData = await response.json();
    return responseData;
  };

  // Note: deprecated
  const getStatementStatistic = async ({
     accessToken,
     year,
     month,
     accountId,
     propertyId,
   }) => {
    const statementStatisticResponse = await fetch(
      `${process.env.REACT_APP_API_GATEWAY_BASE_URL}/payment/api/statements/statementstatistic?year=${year}&month=${month}&accountId=${accountId}&propertyId=${propertyId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'GET',
      }
    );
    const statementStatisticData = await statementStatisticResponse.json();
    return statementStatisticData;
  };

  const getStatementStatisticByStatementId = async ({
    accessToken,
    statementId,
    accountId,
    propertyId,
   }) => {
    const statementStatisticResponse = await fetch(
      `${process.env.REACT_APP_API_GATEWAY_BASE_URL}/payment/api/statements/statement/${statementId}/statistic?accountId=${accountId}&propertyId=${propertyId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'GET',
      }
    );
    const statementStatisticData = await statementStatisticResponse.json();
    return statementStatisticData;
  };

  const getResidentTips = async ({accessToken, accountId}) => {
    const loginResponse = await fetch(
      `${process.env.REACT_APP_API_GATEWAY_BASE_URL}/property/api/tips`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'GET',
      }
    );
    const response = await loginResponse.json();
    if (response?.isSuccessful && response?.response?.length) {
      setTips(response.response);
    }
    return response;
  };

  const getChargePeriod = ({startDateOnly, endDateOnly}) => {
    if (!startDateOnly || !endDateOnly) {
      // throw Error('ChargePeriod Dates Missing');
      return '';
    }
    return `${moment(startDateOnly).format('MM/DD/YYYY')} - ${moment(
      endDateOnly
    ).format('MM/DD/YYYY')} (${
      moment(endDateOnly).diff(moment(startDateOnly), 'days') + 1
    } days)`;
  };

  const getChargeUtility = ({utilityId}) => {
    return (
      utilityInfo.length && utilityInfo?.find((i) => i.utilityId === utilityId)
    );
  };

  const getChargeAmount = (charge) => {
    if (charge.isSkipped) {
      return 'Skipped';
    } else if (charge.isOptOut) {
      return 'Is Opted out';
    } else {
      return `\$${Number(charge.amount).toFixed(2)}`;
    }
  };

  const handleFetchData = async () => {
    const reportUserEmail = queryParams.get('reportUserEmail');
    const isPaperLetter = queryParams.get('isPaperLetter');
    const reportUserPassword = queryParams.get('reportUserPassword');
    const companyId = queryParams.get('companyId');

    const year = queryParams.get('year');
    const month = queryParams.get('month');
    const accountId = queryParams.get('accountId');
    const paperStatementId = queryParams.get('paperStatementId');

    try {
      setIsError(false);
      const loginData = await login(
        reportUserEmail,
        reportUserPassword,
        companyId
      );

      const accessToken = loginData.response.accessToken;
      const statementData = await getStatementById({
        accessToken,
        statementId: paperStatementId
      });

      const tips = await getResidentTips({
        accessToken,
        accountId,
      });

      const autopayDataResp = await getAccountAutopay({
        accessToken,
        accountId,
      });
      if (autopayDataResp.isSuccessful && autopayDataResp.response !== null) {
        setIsAutopayEnabled(true);
      }

      const propertyId = statementData.response.propertyId;

      if (statementData.isSuccessful) {
        const utilityIds = [];
        const billingCycleIds = [];
        const voidedTransactionIds = [];
        statementData.response.statementCharges.items?.map((i) => {
          utilityIds.push(i?.utilityId);
          billingCycleIds.push(i?.billingCycleId);
          if (i.type === TRANSACTION_TYPES_ENUM.Void) {
            voidedTransactionIds.push(i?.metadata?.voidedTransactionId);
          }
        })
        statementData.response.statementCredits.items?.map((i) => {
          utilityIds.push(i?.utilityId);
          billingCycleIds.push(i?.billingCycleId);
          if (i.type === TRANSACTION_TYPES_ENUM.Void) {
            voidedTransactionIds.push(i?.metadata?.voidedTransactionId);
          }
        })

        await getTransactionsDetails({accessToken, transactionIds:voidedTransactionIds }).then(trDetailsResp => {
          setAdditionalTransactionsInfo(trDetailsResp.reduce((prev, current) => {
            if (current?.transactionType === TRANSACTION_TYPES_ENUM.UtilityCharge && current?.metadata?.utilityId) {
              utilityIds.push(current?.metadata?.utilityId)
            }
            return Object.assign({}, prev, {[current.id]: current})
          }, {}))
        })

        const resident = await getResidentInfo({
          accessToken,
          accountIds: [accountId],
        });
        if (resident.isSuccessful) {
          setResidentData(resident.response[0]);
        } else {
          setIsSuccess(false);
          setIsError(true);
        }

        const property = await getPropertyInfo({
          accessToken,
          propertyId: statementData.response.propertyId,
        });
        if (property.isSuccessful) {
          setPropertyData(property.response);
        } else {
          setIsSuccess(false);
          setIsError(true);
        }

        const residentBillingSettingsResp = await getResidentBillingSettings({
          accessToken,
          residencyId: statementData.response.residencyId,
        });

        if (residentBillingSettingsResp.isSuccessful) {
          setResidentBillingSettings(residentBillingSettingsResp.response);
        } else {
          setIsSuccess(false);
          setIsError(true);
        }

        const residentLeaseDetails = await getResidentLeaseDetails({
          accessToken,
          residencyId: statementData.response.residencyId,
        });
        if (residentLeaseDetails.isSuccessful) {
          setResidentLeaseDetails(residentLeaseDetails.response);
        } else {
          setIsSuccess(false);
          setIsError(true);
        }

        const statistic = await getStatementStatisticByStatementId({
          accessToken,
          statementId: paperStatementId,
          propertyId,
          accountId,
        });
        if (statistic.isSuccessful) {
          const portionUtilIds = statistic.response.residentPortion.map(
            (portion) => portion.utilityId
          );
          const utilityInfoData = await getUtilityInfos({
            accessToken,
            utilityIds: Array.from(
              new Set([...utilityIds, ...portionUtilIds])
            ).filter((i) => !!i),
            propertyId
          });
          if (utilityInfoData.isSuccessful) {
            setUtilityInfo(utilityInfoData.response?.utilities);
          } else {
            setIsSuccess(false);
            setIsError(true);
          }

          const residentPortion = statistic.response.residentPortion
            .map((portion) => {
              return {
                ...portion,
                utilityTypeName:
                  utilityInfoData.response?.utilities?.find(
                    (i) => i.utilityId === portion.utilityId
                  )?.utilityTypeName || 'N/A',
              };
            });
          const monthlyBillingHistory = statistic.response.monthlyBillingHistory
            .map((month) => {
              return {
                ...month,
                date: moment(`${month.year} ${month.month}`).format('MMM'),
              };
            })
            .sort((a, b) => {
              return moment(`${a.year} ${a.month}`).diff(
                moment(`${b.year} ${b.month}`)
              );
            });
          const monthlyBillingHistoryResultLimitedToYear =  monthlyBillingHistory.length > 12
              ? monthlyBillingHistory.slice(monthlyBillingHistory.length - 12)
              : monthlyBillingHistory;

          setStatementStatisticData({residentPortion, monthlyBillingHistory: monthlyBillingHistoryResultLimitedToYear});
        } else {
          setIsSuccess(false);
          setIsError(true);
        }

        setReportData({
          ...statementData.response,
        });

        setIsSuccess(true);
      } else {
        setIsSuccess(false);
        setIsError(true);
      }
    } catch (e) {
      setIsSuccess(false);
      setIsError(true);
    }
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  return (
    <div
      className={`statement ${getIsPaperLetter() ? 'statement__isPaper' : ''}`}
    >
      <div className="pdf-wrapper">
        {isSuccess && <div id="successfullReport"/>}
        {isError && <div id="failedReport"/>}
        <div id="due-date-output" style={{display: 'none'}}>
          {reportData.dueOnDateOnly &&
            moment(reportData.dueOnDateOnly).format('MM/DD/YYYY')}
        </div>
        <div className="pdf-content">
          <div className="main-pdf-content">
            <div className="section">
              <div className="header">
                <img
                  src={reportData.propertyLogo || logo}
                  alt="livable logo"
                  className="logo show-e"
                />
                <img
                  src={reportData.propertyLogo || logoBlack}
                  alt="livable logo"
                  className="logo show-paper"
                />
                <div>
                  <h1>
                    {moment(
                      `${queryParams.get('year')}-${queryParams.get('month')}`
                    ).format('MMMM YYYY')}{' '}
                    Statement
                  </h1>
                  <h3>statement id: {reportData.statementCode}</h3>
                </div>
              </div>
              <div className="top-sections">
                <div className="top-left-block">
                  <div className="lob-address"></div>
                </div>
                <div className="top-right-block">
                  <div className="balance">
                    <div className="tenant-info">
                      <h2>
                        {residentData.firstName + ' ' + residentData.lastName}
                      </h2>
                      <p className="tenant-id light-text">
                        Resident ID: {residentData.accountCode}
                      </p>
                      <div className="tenant-address">
                        <p>{propertyData.propertyAddress}</p>
                        <p>Unit: {reportData.unitName}</p>
                        <p>
                          Billing start:{' '}
                          {residentBillingSettings.billingStartDateOnly &&
                              moment(
                                  residentBillingSettings.billingStartDateOnly
                              ).format('MM/DD/YYYY')}
                        </p>
                        <p>Occupancy: {reportData.occupancy}</p>
                      </div>
                    </div>

                    <div className="tenant-balance">
                      <div className="tenant-balance__header">
                        <div>
                          <h2>
                            Total amount due by{' '}
                            {reportData.dueOnDateOnly &&
                              moment(reportData.dueOnDateOnly).format(
                                'MM/DD/YYYY'
                              )}
                          </h2>
                          <p className="mt-sm">
                            For detailed breakdown login:{' '}
                            <a href="https://mycommunity.livable.com">
                              mycommunity.livable.com
                            </a>
                          </p>
                          {isAutopayEnabled && (
                            <p className="autopay-note">
                              <b>AutoPay Note:</b> Congratulations! You're
                              enrolled in AutoPay. You don't need to do anything
                              at this time.
                            </p>
                          )}
                        </div>

                        <div className="tenant-balance__summary">
                          <h2 id="total-amount-due" className="total">
                            ${Number(reportData.totalAmountDue).toFixed(2)}
                          </h2>
                        </div>
                      </div>

                      <div className="tenant-balance__history">
                        <div className="row-with-line">
                          <div className="nowrap">
                            Carry over from last pay period
                          </div>
                          <div className="line"/>
                          <div id="carry-over">
                            $
                            {Number(reportData.carryOverFromLastPeriod).toFixed(
                              2
                            )}
                          </div>
                        </div>
                        <div className="row-with-line net-charges">
                          <div className="nowrap">
                            This month Net Charges
                          </div>
                          <div className="line"></div>
                          <div id="total-charges">
                            ${Number(reportData.total).toFixed(2)}
                          </div>
                        </div>
                        <div className="row-with-line month-charges">
                          <div className="nowrap">
                            This month charges
                          </div>
                          <div className="line"></div>
                          <div id="total-charges">
                            ${Number(reportData.totalCharges).toFixed(2)}
                          </div>
                        </div>
                        <div className="row-with-line month-credits">
                          <div className="nowrap">
                            This month credits
                          </div>
                          <div className="line"></div>
                          <div id="total-charges">
                            ${Number(reportData.totalCredits) > 0 ? '-' : ''}{Number(reportData.totalCredits).toFixed(2)}
                          </div>
                        </div>
                        <div className="row-with-line">
                          <div className="nowrap">
                            Payments received - Thank you!
                          </div>
                          <div className="line"></div>
                          <div>
                            ${Number(reportData.paymentsReceived).toFixed(2)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="section">
              <div className="section-title section-title--primary">
                This month service statement summary
              </div>

              <div className="half-section-container">
                <div className="half-section">
                  <div className="rows-with-line">
                    {reportData?.statementCharges?.items
                      .map((charge, index) => {
                        const utilityData = getUtility(
                          getChargeUtility(charge)?.utilityTypeName ||
                          getChargeUtility(charge)?.type
                        );
                        if (charge?.type === TRANSACTION_TYPES_ENUM.Void && charge?.metadata?.voidedTransactionId) {
                          return (
                            <>
                              {getIsPaperLetter() ? index === maxChargeItemsInListLetter && <PageBreakComponent /> : index === maxChargeItemsInListPDF && <PageBreakComponent /> }
                            <div
                              className="row-with-line"
                              key={
                                  charge?.utilityId || charge?.financialTransactionId || index
                              }
                            >
                              <div className="charge-wrapper">
                                {utilityData.icon()}
                                <span className="small-text">
                                  {TRANSACTION_TYPES[charge?.type] || charge?.type}{''}
                                  {additionalTransactionsInfo[charge?.metadata?.voidedTransactionId].transactionType === TRANSACTION_TYPES_ENUM.UtilityCharge ? (
                                      ': Utility charge' +
                                      `: ${getChargeUtility({utilityId: additionalTransactionsInfo[charge?.metadata?.voidedTransactionId]?.metadata?.utilityId})?.utilityTypeName}` +
                                      `: ${getChargePeriod({
                                        startDateOnly: additionalTransactionsInfo[charge?.metadata?.voidedTransactionId]?.metadata?.serviceStartDateOnly,
                                        endDateOnly: additionalTransactionsInfo[charge?.metadata?.voidedTransactionId]?.metadata?.serviceEndDateOnly
                                      })}`
                                    ) : (
                                      `: ${TRANSACTION_TYPES[additionalTransactionsInfo[charge?.metadata?.voidedTransactionId].transactionType] || additionalTransactionsInfo[charge?.metadata?.voidedTransactionId].transactionType}` +
                                      (
                                        (
                                          !!additionalTransactionsInfo[charge?.metadata?.voidedTransactionId]?.metadata?.feeType &&
                                            ': ' + (TRANSACTION_TYPES[additionalTransactionsInfo[charge?.metadata?.voidedTransactionId].metadata.feeType] || additionalTransactionsInfo[charge?.metadata?.voidedTransactionId].metadata.feeType)
                                        ) ||
                                        (
                                          !!additionalTransactionsInfo[charge?.metadata?.voidedTransactionId]?.metadata?.paymentType &&
                                            ': ' + (TRANSACTION_TYPES[additionalTransactionsInfo[charge?.metadata?.voidedTransactionId].metadata.paymentType] || additionalTransactionsInfo[charge?.metadata?.voidedTransactionId].metadata.paymentType)
                                        ) || ''
                                      )
                                    )
                                  }
                                </span>
                              </div>
                              {/* <div className="line" /> */}
                              <span className="bold-text">
                                {charge?.metadata?.amountBeforeUtilityCap ? (
                                  <>
                                    <s>
                                      $
                                      {Number(
                                        charge?.metadata?.amountBeforeUtilityCap
                                      ).toFixed(2)}
                                    </s>{' '}
                                  </>
                                ) : (
                                  ''
                                )}
                                ${Number(charge.amount).toFixed(2)}
                              </span>
                            </div>
                            </>
                          );
                        } else {
                          return (
                            <>
                              {getIsPaperLetter() ? index === maxChargeItemsInListLetter && <PageBreakComponent /> : index === maxChargeItemsInListPDF && <PageBreakComponent /> }
                              <div
                                className="row-with-line"
                                key={charge.utilityId || charge?.financialTransactionId || index}
                              >
                                <div className="charge-wrapper">
                                {utilityData.icon()}
                                <span className="small-text">
                                  {
                                    (charge?.startDateOnly && charge?.endDateOnly) ?
                                        (`${utilityData?.title}${(charge.amount < 0) ? ' Cap Credit' : ''}: Period: ${getChargePeriod(charge)}`) :
                                        (`${TRANSACTION_TYPES[charge?.type] || charge?.type}${utilityData?.title !== 'Other service type'
                                            ? `: ${utilityData?.title}`
                                            : charge?.metadata?.title && `: ${charge?.metadata?.title}` || ''}`)
                                  }
                                  {charge?.metadata?.paperStatementYear && charge?.metadata?.paperStatementMonth &&
                                    `: Statement period: ${moment(`${charge?.metadata?.paperStatementYear}-${charge?.metadata?.paperStatementMonth}`).format('MMMM YYYY')}`
                                  }
                                </span>
                              </div>
                              {/* <div className="line" /> */}
                              <span className="bold-text">
                                {charge?.metadata?.amountBeforeUtilityCap ? (
                                  <>
                                    <s>
                                      $
                                      {Number(
                                        charge?.metadata?.amountBeforeUtilityCap
                                      ).toFixed(2)}
                                    </s>{' '}
                                  </>
                                ) : (
                                  ''
                                )}
                                {getChargeAmount(charge)}
                              </span>
                            </div>
                            </>
                          );
                        }
                      })}
                    <div className="row-with-line">
                      <h4 className="primary-color nowrap">
                        Current statement charges
                      </h4>
                      <div className="line"/>
                      <h4 className="primary-color">
                        ${Number(reportData?.statementCharges?.total).toFixed(2)}
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="half-section fees-section">
                  <div className="rows-with-line">
                    {/*{reportData?.delayedCharges?.charges?.map((charge) => {*/}
                    {/*  const utilityData = getUtility(*/}
                    {/*    getChargeUtility(charge).utilityTypeName*/}
                    {/*  );*/}
                    {/*  return (*/}
                    {/*    <div className="row-with-line" key={charge.utilityId}>*/}
                    {/*      <div className="charge-wrapper">*/}
                    {/*        {utilityData.icon()}*/}
                    {/*        <span className="small-text">*/}
                    {/*          {utilityData?.title}: Period:{' '}*/}
                    {/*          {getChargePeriod(charge)}*/}
                    {/*        </span>*/}
                    {/*      </div>*/}
                    {/*      /!* <div className="line" /> *!/*/}
                    {/*      <span className="bold-text">*/}
                    {/*        ${Number(charge.amount).toFixed(2)}*/}
                    {/*      </span>*/}
                    {/*    </div>*/}
                    {/*  );*/}
                    {/*})}*/}
                    {reportData?.statementCredits?.items?.map((charge, index) => {
                      const utilityData = getUtility(
                        getChargeUtility(charge)?.utilityTypeName ||
                        getChargeUtility(charge)?.type
                      );
                      if (charge?.type === TRANSACTION_TYPES_ENUM.Void && charge?.metadata?.voidedTransactionId) {
                        return (
                          <>
                            {getIsPaperLetter() ? index === maxChargeItemsInListLetter && <PageBreakComponent /> : index === maxChargeItemsInListPDF && <PageBreakComponent /> }
                          <div
                              className="row-with-line"
                              key={
                                  charge?.utilityId || charge?.financialTransactionId || index
                              }
                          >
                            <div className="charge-wrapper">
                              {utilityData.icon()}
                              <span className="small-text">
                                {TRANSACTION_TYPES[charge?.type] || charge?.type}{''}
                                  {additionalTransactionsInfo[charge?.metadata?.voidedTransactionId].transactionType === TRANSACTION_TYPES_ENUM.UtilityCharge ? (
                                    ': Utility charge' +
                                    `: ${getChargeUtility({utilityId: additionalTransactionsInfo[charge?.metadata?.voidedTransactionId]?.metadata?.utilityId})?.utilityTypeName}` +
                                    `: ${getChargePeriod({
                                        startDateOnly: additionalTransactionsInfo[charge?.metadata?.voidedTransactionId]?.metadata?.serviceStartDateOnly,
                                        endDateOnly: additionalTransactionsInfo[charge?.metadata?.voidedTransactionId]?.metadata?.serviceEndDateOnly
                                    })}`
                                    ) : (
                                      `: ${TRANSACTION_TYPES[additionalTransactionsInfo[charge?.metadata?.voidedTransactionId].transactionType] || additionalTransactionsInfo[charge?.metadata?.voidedTransactionId].transactionType}` +
                                      (
                                        (
                                          !!additionalTransactionsInfo[charge?.metadata?.voidedTransactionId]?.metadata?.feeType &&
                                          ': ' + (TRANSACTION_TYPES[additionalTransactionsInfo[charge?.metadata?.voidedTransactionId].metadata.feeType] || additionalTransactionsInfo[charge?.metadata?.voidedTransactionId].metadata.feeType)
                                        ) ||
                                        (
                                          !!additionalTransactionsInfo[charge?.metadata?.voidedTransactionId]?.metadata?.paymentType &&
                                          ': ' + (TRANSACTION_TYPES[additionalTransactionsInfo[charge?.metadata?.voidedTransactionId].metadata.paymentType] || additionalTransactionsInfo[charge?.metadata?.voidedTransactionId].metadata.paymentType)
                                        ) || ''
                                      )
                                    )
                                  }
                              </span>
                            </div>
                            {/* <div className="line" /> */}
                            <span className="bold-text">
                              {charge?.metadata?.amountBeforeUtilityCap ? (
                                <>
                                  <s>
                                    $
                                    {Number(
                                      charge?.metadata?.amountBeforeUtilityCap
                                    ).toFixed(2)}
                                  </s>{' '}
                                </>
                              ) : (
                                ''
                              )}
                              ${Number(charge.amount).toFixed(2)}
                            </span>
                          </div>
                          </>
                        );
                      } else {
                        return (
                          <>
                            {getIsPaperLetter() ? index === maxChargeItemsInListLetter && <PageBreakComponent /> : index === maxChargeItemsInListPDF && <PageBreakComponent /> }
                          <div
                            className="row-with-line"
                            key={
                                charge?.utilityId || charge?.financialTransactionId || index
                            }
                          >
                            <div className="charge-wrapper">
                              {utilityData.icon()}
                              <span className="small-text">
                                {TRANSACTION_TYPES[charge?.type] || charge?.type}{''}
                                {utilityData?.title !== 'Other service type' &&
                                    `: ${utilityData?.title} ` || charge?.metadata?.title && `: ${charge?.metadata?.title}`}
                                {charge?.metadata?.paperStatementYear && charge?.metadata?.paperStatementMonth &&
                                    `: Statement period: ${moment(`${charge?.metadata?.paperStatementYear}-${charge?.metadata?.paperStatementMonth}`).format('MMMM YYYY')}`
                                }
                              </span>
                            </div>
                            {/* <div className="line" /> */}
                            <span className="bold-text">
                              {charge?.metadata?.amountBeforeUtilityCap ? (
                                <>
                                  <s>
                                    $
                                    {Number(
                                      charge?.metadata?.amountBeforeUtilityCap
                                    ).toFixed(2)}
                                  </s>{' '}
                                </>
                              ) : (
                                ''
                              )}
                              ${Number(charge.amount).toFixed(2)}
                            </span>
                          </div>
                          </>
                        );
                      }

                    })}
                  </div>

                  <div className="row-with-line">
                    <h4 className="primary-color nowrap">
                      Current statement credits
                    </h4>
                    <div className="line"/>
                    <h4 className="primary-color">
                      ${Number(reportData?.statementCredits?.total).toFixed(2)}
                    </h4>
                  </div>
                </div>
              </div>

              <div className="graphics">
                <div className="half-section-container">
                  <div className="half-section">
                    <div className="section-title">
                      Building utility cost vs your portion (
                      {moment(
                        `${queryParams.get('year')}-${queryParams.get('month')}`
                      ).format('MMMM YYYY')}
                      )
                    </div>
                    <StatementBarChart
                      key={statementStatisticData.residentPortion}
                      residentPortion={statementStatisticData.residentPortion}
                    />
                  </div>
                  <div className="half-section">
                    <div className="section-title">
                      Monthly utility billing history (Last 12 months)
                    </div>
                    <StatementLineChart
                      key={statementStatisticData.monthlyBillingHistory}
                      monthlyBillingHistory={
                        statementStatisticData.monthlyBillingHistory
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="section-title">Payment options</div>

              <div className="payment-options">
                <div className="rows-with-line">
                  <div className="row-with-line">
                    <div className="charge-wrapper">
                      <img
                        src={macfill}
                        className="icon show-e"
                        alt="laptop-icon"
                      />
                      <img
                        src={macfillBlack}
                        className="icon show-paper"
                        alt="laptop-icon"
                      />
                      <span className="bold-text">Online</span>
                    </div>
                    <div className="line"></div>
                    <div className="nowrap text-right">
                      <span className="small-text">
                        {/*Log in{' '}*/}
                        {/*<span className="bold-text">to <a href="https://mycommunity.livable.com">mycommunity.livable.com</a></span>{' '}*/}
                        {/*to pay. You can also see details on how each utility was*/}
                        {/*allocated*/}
                        You can set up online payments via ACH or a credit/debit
                        card. <br/>
                        {!isAutopayEnabled &&
                          'Enroll in our optional AutoPay so you never miss a payment.'}
                      </span>
                    </div>
                  </div>
                  <div className="row-with-line">
                    <div className="charge-wrapper">
                      <img
                        src={filelist2fill}
                        className="icon show-e"
                        alt="list-icon"
                      />
                      <img
                        src={filelist2fillBlack}
                        className="icon show-paper"
                        alt="list-icon"
                      />
                      <span className="bold-text">Property Manager</span>
                    </div>
                    <div className="line"></div>
                    <div className="text-right">
                      <span className="nowrap small-text">
                        If you don’t want to pay online, pay your Property
                        Manager or
                        <br/>
                        Housing Provider directly using whatever method you
                        agree on.
                        {/*<span className="bold-text">888-777-6666</span>*/}
                      </span>
                    </div>
                  </div>
                  {/*<tr>*/}
                  {/*  <td>*/}
                  {/*    <img src={phonefill} className="icon" />*/}
                  {/*    <span className="bold-text">By phone</span>*/}
                  {/*  </td>*/}
                  {/*  <td className="table-line">*/}
                  {/*    <hr />*/}
                  {/*  </td>*/}
                  {/*  <td className="text-right">*/}
                  {/*    <span className="small-text">*/}
                  {/*      You can call toll free{' '}*/}
                  {/*      <span className="bold-text">888-777-6666</span>*/}
                  {/*    </span>*/}
                  {/*  </td>*/}
                  {/*</tr>*/}
                  {/*<tr>*/}
                  {/*  <td>*/}
                  {/*    <img src={filelist2fill} className="icon" />*/}
                  {/*    <span className="bold-text">Check</span>*/}
                  {/*  </td>*/}
                  {/*  <td className="table-line">*/}
                  {/*    <hr />*/}
                  {/*  </td>*/}
                  {/*  <td className="text-right">*/}
                  {/*    <span className="small-text">*/}
                  {/*      Please send to{' '}*/}
                  {/*      <span className="bold-text">*/}
                  {/*        30 N Gould St Ste 25746 Sheridan, WY 82801*/}
                  {/*      </span>{' '}*/}
                  {/*      and include your resident ID and building address in the*/}
                  {/*      memo section.*/}
                  {/*    </span>*/}
                  {/*  </td>*/}
                  {/*</tr>*/}
                </div>

                <div className="qr-block">
                  {reportData.paymentUrl && (
                    <QRCode
                      size={48}
                      style={{
                        flexShrink: 0,
                        marginRight: '4pt',
                      }}
                      value={reportData.paymentUrl}
                      viewBox={`0 0 48 48`}
                    />
                  )}
                  <div className="small-text">
                    Scan to open mobile payment form
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={`section ${(getIsPaperLetter() && getMaxChargesCreditsNumberReached()) 
              ? 'cut-saver-section'
              : ''}`
          }>
            <div className="footer">
              <div className="saver">
                <h5>
                  Want to save money on utilities? Check Super Saver tip of the
                  month:
                </h5>
                {tips[queryParams.get('month')] ? (
                  <p className="small-text">
                    {tips[queryParams.get('month')].tip}
                  </p>
                ) : (
                  <>
                    <p className="small-text">
                      Insulating water pipes is a quick and inexpensive way to
                      lower water heating costs. The easy upgrade reduces heat
                      loss and allows you to lower your buildings’ water
                      temperature setting by two to four degrees. Plus, since
                      water doesn’t take as long to heat up, less of this
                      precious resource is wasted waiting for faucets and
                      showers to get to the appropriate temperature.
                    </p>
                    <p className="small-text">
                      This is a definite DIY job on smaller buildings that
                      should take less than a day to complete. Check out the
                      U.S. Department of Energy’s website for a comprehensive
                      shopping list and step-by-step instructions.
                    </p>
                  </>
                )}
                <p className="small-text light-text">
                  More tips @{' '}
                  <a href="https://comesave.livable.com/community">
                    https://comesave.livable.com/community
                  </a>{' '}
                  or in your Resident portal.
                </p>
              </div>
              {/*<div className="footer-links">*/}
              {/*  <span className="footer-link">*/}
              {/*    <span className="light-text">Livable Community:</span>{' '}*/}
              {/*    https://comesave.livable.com/community*/}
              {/*  </span>*/}
              {/*  <span className="footer-link">*/}
              {/*    <span className="light-text">More conservation tips at:</span>{' '}*/}
              {/*    https://blog.livable.com*/}
              {/*  </span>*/}
              {/*  <span className="footer-link">*/}
              {/*    <span className="light-text">*/}
              {/*      Manage your account and pay online:*/}
              {/*    </span>{' '}*/}
              {/*    https://resident.livable.com*/}
              {/*  </span>*/}
              {/*  <span className="footer-link">*/}
              {/*    <span className="light-text">Need help?</span>{' '}*/}
              {/*    statements@livable.com*/}
              {/*  </span>*/}
              {/*</div>*/}
              <br/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatementV2;
